<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('generic-str.status.lbl-new')}}</h5>
          <button type="button" class="close" @click="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="control-label text-left text-left-m">Nome</label>
                  <input v-model="form.name" type="text" class="form-control"/>
                </div>
                <div class="col-md-6">
                  <label class="control-label text-left text-left-m">Email de contato</label>
                  <input v-model="form.business_mail" type="text" class="form-control"/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <label class="control-label text-left text-left-m">Nome da empresa</label>
                  <input v-model="form.business_name" type="text" class="form-control"/>
                </div>
                <div class="col-md-6">
                  <label class="control-label text-left text-left-m">Telefone</label>
                  <input v-model="form.phone_number" type="text" class="form-control"/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <label class="control-label text-left text-left-m">Link do site</label>
                  <input v-model="form.site_link" type="text" class="form-control"/>
                </div>
                <div class="col-md-6">
                  <label class="control-label text-left text-left-m">URL da politica de privacidade do site</label>
                  <input v-model="form.privacy_link" type="text" class="form-control"/>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">URL dos termos de uso do site</label>
                <div class="col-md-12">
                  <input v-model="form.term_link" type="text" class="form-control"/>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">Opt-in</label>
                <div class="col-md-12">
                  <textarea v-model="form.opt_in" type="text" class="form-control"></textarea>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-8 col-lg-8">
                  <label class="control-label text-right text-center"
                    >Imagem grande do banner (1440 x 448)</label>
                  <div class="image-cover" id="cover-banner"></div>
                  <a @click="callCrop('cover-banner', 1440, 448, 0)" class="c-pointer btn btn-primary btn-upload"><span class="material-symbols-outlined">upload</span> Fazer upload</a>
                </div>
                <div class="col-md-4 col-lg-4 text-center">
                  <label class="control-label text-right"
                    >Logo (224 x 224)</label>
                  <div class="image-cover" id="cover-logo"></div>
                  <a @click="callCrop('cover-logo', 224, 224, 1)" class="c-pointer btn btn-primary btn-upload"><span class="material-symbols-outlined">upload</span> Fazer upload</a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-yup-purple"
            >
              {{$t('generic-str.save')}}
            </button>
            <button type="button" class="btn btn-yup-purple-outline" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import BucketService from '@/services/bucket.service';
import axios from '@/plugins/axios';
import Swal from 'sweetalert2';

const webApi = axios();

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
  },
  data() {
    return {
      form: {},
      isSending: false,
      cropPreview: null,
      files: [{}, {}],
    };
  },
  created() {
    this.$root.$emit('show.modal', 'request-modal');
  },
  beforeDestroy() {
  },
  methods: {
    close() {
      this.$root.$off('call.crop', () => { });
      this.hide();
    },
    crop(data) {
      if (data) {
        document.getElementById(this.cropPreview).style = `background: url(${data.url}); background-size: cover;`;
        this.files[data.name] = data;
      }

      console.log(this.files);
    },
    callCrop(cropPreview, maxWidthP, maxHeightP, index) {
      this.$root.$on('call.crop', this.crop);
      this.cropPreview = cropPreview;
      console.log(index);
      this.$store.dispatch('crop/config', {
        maxWidth: maxWidthP,
        maxHeight: maxHeightP,
        name: index,
        viewMode: 2,
        aspectRatio: 1,
        minSize: true,
      });

      this.$root.$emit('show.modal', 'm-crop');
      this.hide(false, false);
    },
    changeCountry(e) {
      const tempNumber = this.form.phone_number
        .replace(/\D/g, '')
        .split('(')[1];
      this.form.phone_number = `${e.dialCode}${tempNumber}`;
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    save() {
      if (this.files[1].file === undefined) {
        this.$toast.show({
          title: 'Aviso',
          content: 'Você precisa fazer uploado da logo',
          type: 'danger',
        });

        return;
      }

      this.isSending = true;

      const filesCrop = [];
      filesCrop.push({
        x: this.files[0].x,
        y: this.files[0].y,
        w: this.files[0].width,
        h: this.files[0].height,
      });
      filesCrop.push({
        x: this.files[1].x,
        y: this.files[1].y,
        w: this.files[1].width,
        h: this.files[1].height,
      });
      const formDataCover = new FormData();
      formDataCover.append('files[]', this.files[0].file);
      formDataCover.append('files[]', this.files[1].file);
      formDataCover.append('role', 'public');
      formDataCover.append('folder', 'rcs');
      formDataCover.append('crop', JSON.stringify(filesCrop));

      Promise.all([
        BucketService.upload(formDataCover, true, this.callBackProgress),
      ])
        .then(
          (responses) => {
            this.isSending = false;
            console.log(responses);
            this.form.banner_url = responses[0].data[1].url;
            this.form.logo_url = responses[0].data[0].url;
            console.log(responses);
            this.createAgent();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
            this.$toast.show({
              title: 'Erro interno',
              content: 'Erro ao tentar fazer upload.',
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    createAgent() {
      this.isSending = true;
      webApi.post('/channels/rcs/active', this.form)
        .then(
          (response) => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Solicitação enviada',
              type: 'success',
            });
            this.$emit('agent.submit');
            this.isSending = false;
            Swal.fire('Sucesso!', 'Solicitação enviada com sucesso. Breve entraremos em contato.', 'success');
            this.hide();
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                error.code
              }`,
              content: error.response.data.message,
              type: 'danger',
            });
          },
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.image-cover{
  border: 1px solid rgba(120,141,180,.3);
  width: 100%;
  height: 200px;
}
.btn-upload {
  margin-top: 5px;
}
.btn-upload span {
  float: left;
  font-size: 20px;
}
</style>
