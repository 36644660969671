<template>
  <div>
    <div class="how-work-header">
      <div class="how-work-info">
        <span class="material-symbols-outlined">info</span>
      </div>
      <div>
        <div class="how-work-info-title">
          <strong>Como Funciona</strong>
        </div>
        <div>
          <p>
            {{message}}
          </p>
        </div>
      </div>
    </div>
    <div class="how-work-header m-top-10">
      <div class="how-work-info">
        <span class="material-symbols-outlined">info</span>
      </div>
      <div>
        <div class="how-work-info-title">
          <strong>Recursos </strong>
        </div>
        <div>
          <p>
            O suporte a recursos varia de acordo com o canal. Verifique a disponibilidade de recursos abaixo.
          </p>
        </div>
        <div class="feature-list border-bottom-header">
          <div style="justify-content: left;font-size: 15px;"><span>Recurso</span></div>
          <div style="font-size: 15px;"><span>Campanhas</span></div>
          <div style="justify-content: right;font-size: 15px;"><span>Chat</span></div>
        </div>
        <div class="feature-list" v-for="(feature, i) in features" :key="i">
          <div style="justify-content: left;"><span>{{feature.text}}</span></div>
          <div><span class="material-symbols-outlined check-color" v-if="feature.campaingn">check</span></div>
          <div style="justify-content: right;"><span class="material-symbols-outlined check-color" v-if="feature.chat">check</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChannelFeatures',
  props: {
    features: {
      type: Array,
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.how-work-header {
  display: flex;
  flex-direction: row;
  padding-right: 35px;
}
.how-work-info {
  margin-right: 10px;
}
.how-work-info span {
  color: #0070E4;
}

.how-work-header {
  background: #F1F7FD;
  border-radius: 8px;
  padding: 16px;
}
.how-work-info-title strong {
  color: #344054;
  font-weight: 700;
  font-size: 12px;
}
.how-work-header p {
  color: #667085;
  margin-top: 10px;
  font-size: 12px;
}
.m-top-10 {
  margin-top: 10px;
}
.m-top-20 {
  margin-top: 20px;
}
.feature-list {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.feature-list div {
  display: flex;
  flex: 1;
  text-align: center;
  color: #344054;
  font-weight: 400;
  font-size: 12px;
  justify-content: center;
}
.check-color {
  color: #16870E;
}
.border-bottom-header {
  border-bottom: 1px #344054 solid !important;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
</style>
