<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Novo bot Telegram</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="create" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-right col-md-4 text-left-m"
                  >Token</label
                >
                <div class="col-md-6">
                  <input
                    v-model="form.token"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-primary"
            >
              Ativar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import ChannelService from '@/services/channel.service';

export default {
  components: {
  },
  mixins: [Modal],
  props: ['id', 'group'],
  data() {
    return {
      groups: [],
      mobile_number: null,
      invalidPhone: false,
      bindProps: {
        mode: 'international',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      form: {
        auth: '',
        bot_id: null,
        token: '',
        channel_live_id: process.env.VUE_APP_TELEGRAM_LIVE_ID,
        channel_id: process.env.VUE_APP_TELEGRAM_ID,
        end_point_send: 'http://localhost:8097/send/telegram',
      },
      isSending: false,
    };
  },
  created() {
  },
  methods: {
    create() {
      if (this.form.token) {
        this.isSending = true;

        ChannelService.createTelegramBot(this.form).then(
          () => {
            this.$root.$emit('sentTelegram');
            this.hide();
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Bot adicionado',
              type: 'success',
            });
            this.isSending = false;
          },
          (error) => {
            this.isSending = false;
            if (error.response.data.message.includes('409 Conflict')) {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: 'Bot já está cadastrado',
                type: 'danger',
              });
            } else {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
            }
          },
        );
      } else {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: 'As informações precisam ser preenchidas',
          type: 'danger',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.vti__dropdown-list {
  z-index: 1000 !important;
}
.vue-tel-input {
  border-color: #dfe7f3 !important;
  border: 1px solid rgba(120, 141, 180, 0.3) !important;
}
</style>
